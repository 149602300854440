<template>
  <div
    class="input-number-control-container"
    :style="{ 'max-width': maxWidthInput }"
  >
    <v-hover #default="{ hover }">
      <v-text-field
        class="number-input-field"
        v-model="fieldValue"
        v-bind="$attrs"
        v-on="$listeners"
        flat
        solo
        :background-color="focusField || hover || !!value || value === min ? 'inputFocus' : ''"
        @focus="changeFocus(true)"
        @blur="changeFocus(false)"
        @keypress="onlyNumbers"
        @change="checkValue"
        type="number"
        :min="min"
        :step="step"
      >
        <template #append>
          <div class="d-flex flex-column control-container">
            <v-icon
              size="12"
              color="defaultIconColor"
              @click="inc"
            >$arrowUp</v-icon>
            <v-icon
              size="12"
              color="defaultIconColor"
              @click="dec"
            >$arrowDown</v-icon>
          </div>
        </template>

        <slot v-for="slot in Object.keys($slots)" :name="slot" :slot="slot"/>
        <template v-for="(_, slot) of $scopedSlots" v-slot:[slot]="scope">
          <slot :name="slot" v-bind="scope"/>
        </template>
      </v-text-field>
    </v-hover>
  </div>
</template>

<script>
export default {
  name: 'InputNumberControl',
  inheritAttrs: false,
  props: {
    value: {
      type: Number | String,
      default: 1
    },
    min: {
      type: Number | String,
      default: 1
    },
    step: {
      type: Number | String,
      default: 1
    },
    width: {
      type: String | Number,
      default: 0
    },
  },
  data: () => ({
    focusField: false
  }),
  computed: {
    fieldValue: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    },
    maxWidthInput() {
      if (!!this.width) {
        return this.width + 'px'
      } else {
        const length = this.fieldValue.toString().length > 4 ? this.fieldValue.toString().length -4 : 0
        const width = 77 + length * 7.6
        return width > 260 ? '260px' : width + 'px'
      }
    }
  },
  methods: {
    inc() {
      this.fieldValue = +(+this.fieldValue + parseFloat(this.step)).toFixed(2)
    },
    dec() {
      if (this.fieldValue > this.min) {
        this.fieldValue = +(parseFloat(this.fieldValue) - parseFloat(this.step)).toFixed(2)
      }
    },
    changeFocus(val) {
      this.focusField = val
    },
    onlyNumbers(event) {
      const regex = new RegExp('^[0-9]*[,.]?[0-9]+(?:[eE][-+]?[0-9]+)?$')
      const key = `${this.fieldValue}` + String.fromCharCode(!event.charCode ? event.which : event.charCode)
      if (isNaN(key) || !regex.test(parseFloat(key))) {
        event.preventDefault()
        return false
      }
    },
    checkValue(val) {
      if (val === '' || +val < this.min) {
        this.fieldValue = this.min
      }
    }
  }
}
</script>

<style lang="scss">
.minHeight36 {
  & > .v-input.v-text-field.number-input-field {
    & > .v-input__control {
      min-height: 36px;
    }
  }
}


.v-input.v-text-field.number-input-field {
  & > .v-input__control {
    & > .v-input__slot {
      padding: 0 10px 0 14px!important;
      
      & > .v-text-field__slot {
        & > input {
          font-weight: 500!important;
          font-size: 14px!important;
          line-height: 17px!important;
          letter-spacing: 0.008em!important;
        }
      }
    }
    
    .control-container {
      .v-icon {
        &:first-child {
          margin-bottom: -3px;
        }
        &:last-child {
          margin-top: -3px;
        }
      }
    }
  }
}
</style>
