<template>
  <v-container fluid class="pa-8 pa-sm-15">
    <div class="header-block d-flex align-center justify-space-between pb-8 pb-sm-15">
      <div class="d-flex align-center">
        <portal to="pageTitle" :disabled="checkMd">
          <div class="font-page-header pr-5 pr-sm-15">routing plan</div>
        </portal>
        
        <ActionButton @click="closeAction">Manage routing plans</ActionButton>
      </div>
      
      <div>
        <CancelButton @click="closeAction">Close</CancelButton>
      </div>
    </div>
    
    <v-card class="create-card no-padding cardBg" flat outlined>
      <div class="setup-container">
        <RoutingPlanSetup
          :description="description.setup"
          :routingPlanName="routingPlanName"
          :routingPlanSlug="routingPlanSlug"
          :loadingDetailRoutingPlan="loading"
        />
      </div>
      
      <CallRouting
        :description="description.destinationPanel"
        :routingPlanDestinationsAndGroups="routingPlanDestinationsAndGroups"
        :routingPlanSlug="routingPlanSlug"
        :loadingDetailRoutingPlan="loading"
        :freeDestinations="manageDestinations"
        :freeGroups="manageGroups"
        @reloadCampaignManage="goToLoadRoutingPlanManage"
      />
    </v-card>
  </v-container>
</template>

<script>
import ActionButton from '@/components/buttons/ActionButton'
import CancelButton from '@/components/buttons/CancelButton'
import RoutingPlanSetup from '@/components/routing-plan/RoutingPlanSetup'
import CallRouting from '@/components/routing-plan/CallRouting'

import { getDetailRoutingPlan, getManageForRoutingPlan } from '@/api/routing-plan-routes'

import { mapGetters } from 'vuex'
import { Types as authTypes } from '@/store/modules/auth'

export default {
  name: 'DetailRoutingPlan',
  components: { ActionButton, CancelButton, RoutingPlanSetup, CallRouting },
  data: () => ({
    loading: false,
    routingPlanSlug: null,
    noScrolling: false,
    detailRoutingPlan: null,
    description: {
      setup: {
        title: 'Setup Routing Plan',
        text: [
          'This name will be used to',
          'identify the routing plan',
          'throughout the Dialics portal.'
        ]
      },
      destinationPanel: {
        destination: {
          title: 'Add a Destination',
          text: [
            'Forward your calls to buyers, call ',
            'centers, or anywhere you\'d like them',
            'to go.',
          ]
        },
        addDestination: {
          title: 'ADD DESTINATION',
          text: []
        },
        editCallRoute: {
          title: 'EDIT CALL ROUTE',
          text: []
        },
        createDestination: {
          title: 'CREATE DESTINATION',
          text: []
        },
        addGroup: {
          title: 'ADD GROUP',
          text: []
        },
        createGroup: {
          title: 'CREATE GROUP',
          text: []
        },
      },
    },
    manageDestinations: [],
    manageGroups: [],
  }),
  computed: {
    ...mapGetters({
      getCurrentAccount: authTypes.getters.GET_CURRENT_ACCOUNT,
    }),
    checkSm() {
      return this.$vuetify.breakpoint.width < 600
    },
    checkMd() {
      return this.$vuetify.breakpoint.width > 1029
    },
    routingPlanName() {
      return this.detailRoutingPlan ? this.detailRoutingPlan.name : ''
    },
    routingPlanDestinationsAndGroups() {
      const routingPlanDestinations = !!this.detailRoutingPlan ? this.detailRoutingPlan.targets.filter(destination => !destination.hide) : []
      const routingPlanGroups = !!this.detailRoutingPlan ? this.detailRoutingPlan['target_groups'].map(group => ({
        ...group,
        isGroup: true
      })) : []
      
      return routingPlanDestinations.concat(routingPlanGroups)
    }
  },
  mounted() {
    if (this.$route.params.slug) {
      this.noScrolling = !this.$route.query?.afterCreate
      this.routingPlanSlug = this.$route.params.slug
      this.goToLoadDetailRoutingPlan()
      this.goToLoadRoutingPlanManage()
    }
  },
  methods: {
    closeAction() {
      this.$router.push({ name: 'ManageRoutingPlans' })
    },
    async goToLoadDetailRoutingPlan() {
      this.loading = true

      const { success, payload, message } = await getDetailRoutingPlan(this.routingPlanSlug)

      if (success) {
        this.detailRoutingPlan = payload
      } else {
        !!message && this.$notify({ type: 'error-bg', duration: 15000, text: message })
      }

      this.loading = false
    },
    async goToLoadRoutingPlanManage() {
      const { success, payload, message } = await getManageForRoutingPlan(this.routingPlanSlug)

      if (success) {
        this.manageDestinations = payload['targets']
        this.manageGroups = payload['targetGroups']
      } else {
        !!message && this.$notify({ type: 'error-bg', duration: 15000, text: message })
      }
    },
  },
  watch: {
    routingPlanSlug: {
      handler(value) {
        if (value && !this.noScrolling) {
          setTimeout(() => {
            this.$vuetify.goTo('#call-routing')
          })
        }
        this.noScrolling = false
      }
    },
  },
}
</script>
