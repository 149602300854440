<template>
  <v-data-table
    :headers="headers"
    :items="items"
    item-key="slug"
    class="elevation-0 borderNone font-small tableBg borderTableXS call-routing-table withWeight"
    outlined
    disable-pagination
    hide-default-footer
    :custom-sort="customSort"
    :header-props="{ sortIcon: '$sortDownIcon' }"
    :loading="loadingDetail"
    :loader-height="2"
    :mobile-breakpoint="0"
  >
    <template #item.priority="{ item }">
      <TableInputNumberControl
        v-model="item.pivot.priority"
        :item="item"
        :loadingStatus="loadingStatus"
        isPriority
        @changeValueAction="updatePriority(item, $event)"
      />
    </template>

    <template #item.weight="{ item }">
      <TableInputNumberControl
        v-model="item.pivot.weight"
        :item="item"
        :loadingStatus="loadingStatus"
        hideArrow
        :max="100"
        @changeValueAction="updateWeight(item, $event)"
      />
    </template>
    
    <template #item.destination="{ item }">
      {{ item.destination ? `+${item.destination}` : item['sip_point'] ? item['sip_point'] : 'Group' }}
    </template>
    
    <template #item.type="{ item }">
      <TooltipAction
        :message="getConversionNameById(item.pivot['convert_on_id'])"
        :openDelay="300"
        bottom
      >
        <template #activator>
          <div class="text-truncate width--84">
            {{ getConversionNameById(item.pivot['convert_on_id']) }}
          </div>
        </template>
      </TooltipAction>
    </template>
    
    <template #item.revenue="{ item }">
      {{ currency(item.pivot.revenue) }}
    </template>
    
    <template #item.status_id="{ item }">
      <SwitchSm
        :value="getStatus(item)"
        :loading="loadingStatus"
        bgColor="switcherYellow"
        @input="changeStatus(item)"
      />
    </template>
    
    <template #item.actions="{ item }">
      <TooltipAction bottom message="Edit">
        <template #activator>
          <v-icon class="mr-6" @click="editCallRoute(item)">$pencilIcon</v-icon>
        </template>
      </TooltipAction>
      
      <TooltipAction bottom message="Delete">
        <template #activator>
          <v-icon @click="deleteCallRouting(item)">$deleteIcon</v-icon>
        </template>
      </TooltipAction>
    </template>
    
    <template #no-data>
      <div class="d-flex pl-7">
        <span class="font-normal text-left" v-if="!callRoutes.length">No Destinations linked to this {{ isRoutingPlan ? 'Routing plan' : 'Campaign'}}</span>
        <span class="font-normal text-left" v-else>No results found</span>
      </div>
    </template>
  
    <template #top>
      <DialogConfirm
        v-model="confirmDialog"
        :loading="loadingAction"
        :title="dialogTitle"
        :subTitle="dialogSubTitle"
        :message="dialogMessage"
        @actionSuccess="goToDeleteCallRouting"
      />
    </template>
  </v-data-table>
</template>

<script>
import TooltipAction from '@/components/tooltip/TooltipAction'
import SwitchSm from '@/components/controls/SwitchSm'
import TableInputNumberControl from '@/components/inputs/TableInputNumberControl'
import DialogConfirm from '@/components/dialog/DialogConfirm'

import currency from '@/mixins/currency'

import { editAttachedDestination, editAttachedGroup, detachGroupFromCampaign, detachDestinationFromCampaign } from '@/api/campaign-routes'
import { editAttachedDestinationToRoutingPlan, editAttachedGroupToRoutingPlan, detachDestinationFromRoutingPlan, detachGroupFromRoutingPlan } from '@/api/routing-plan-routes'
import { changeDestinationStatus } from '@/api/destination-routes'
import { updateGroup } from '@/api/group-routes'
import { changeItemsAfterUpdate, customSorting } from '@/helper/app-helper'

export default {
  name: 'AddedDestinations',
  inheritAttrs: false,
  components: { TooltipAction, SwitchSm, TableInputNumberControl, DialogConfirm },
  mixins: [ currency ],
  props: {
    callRoutes: {
      type: Array,
      required: true
    },
    loadingDetail: {
      type: Boolean,
      required: true
    },
    campaignSlug: {
      type: String | Number,
      default: null
    },
    routingPlanSlug: {
      type: String | Number,
      default: null
    },
    conversionOptions: {
      type: Array,
      required: true
    },
    isRoutingPlan: {
      type: Boolean,
      default: false
    },
    search: {
      type: String | Number,
      default: ''
    },
  },
  data: () => ({
    loadingStatus: false,
    loadingAction: false,
    headers: [
      { text: 'PRIORITY', value: 'priority', align: 'center', class: 'pr-4' },
      { text: 'WEIGHT', value: 'weight', align: 'center', class: 'pr-12' },
      { text: 'NAME', value: 'name' },
      { text: 'NUMBER', value: 'destination', sortable: false },
      { text: 'TYPE', value: 'type' },
      { text: 'REVENUE', value: 'revenue', align: 'center' },
      { text: 'STATUS', value: 'status_id', align: 'center' },
      { text: 'ACTIONS', value: 'actions', sortable: false, align: 'right' },
    ],
    confirmDialog: false,
    dialogTitle: null,
    dialogSubTitle: null,
    dialogMessage: null,
    selectedItem: null,
    messageForWeight: `Will be applied if campaign's "weights sorting" option is enabled`
  }),
  computed: {
    items() {
      return this.searchItems(this.callRoutes, this.search)
    },
  },
  methods: {
    searchItems(items, search) {
      if (!search) return items
      search = search.toString().toLowerCase()
      if (search.trim() === '') return items

      return items.filter(item => {
        if (item.name.toString().toLowerCase().indexOf(search) !== -1) return true

        let prev = search[0] === '+' ? '+' : ''

        if (!!item.destination && `${prev}${item.destination.toString().toLowerCase()}`.indexOf(search) !== -1) return true

        if (this.getConversionNameById(item.pivot['convert_on_id']).toString().toLowerCase().indexOf(search) !== -1) return true

        prev = search[0] === '$' ? '$' : ''

        return `${prev}${item.pivot.revenue.toString()}`.indexOf(search) !== -1
      })
    },
    getStatus(item) {
      return item['status_id'] === 1
    },
    getConversionNameById(id) {
      const option = this.conversionOptions.find(option => option.id === id)
      return option?.name ? option.name : ''
    },
    updatePriority(route, priority) {
      route.pivot.priority = !!(+priority) ? +priority : 1
      this.changePriorityAction(route, { priority: route.pivot.priority })
    },
    updateWeight(route, weight) {
      route.pivot.weight = !!(+weight)
        ? +weight > 100
          ? 100
          : +weight
        : 1
      this.changePriorityAction(route, { weight: route.pivot.weight })
    },
    changePriorityAction(route, options) {
      const itemName = route.isGroup ? 'group' : 'target'
      const formData = {
        priority: route.pivot.priority,
        weight: route.pivot.weight,
        ...options,
        revenue: route.pivot.revenue,
        'convert_on_id': route.pivot['convert_on_id'],
        'duplicate_id': route.pivot['duplicate_id'],
        [itemName]: route.slug,
        'start_length_on_id': route.pivot['start_length_on_id'] ? route.pivot['start_length_on_id'] : null,
        'time_limit': route.pivot['time_limit'] ? route.pivot['time_limit'] : null,
        length: route.pivot.length ? route.pivot.length : null,
      }
      
      if (route.isGroup) {
        this.changeGroupPriority(route, formData)
      } else {
        this.changeDestinationPriority(route, formData)
      }
    },
    async changeGroupPriority(route, formData) {
      this.loadingStatus = true
      
      const { success, payload, message } = this.isRoutingPlan
        ? await editAttachedGroupToRoutingPlan({ routingPlanId: this.routingPlanSlug, formData })
        : await editAttachedGroup({ campaignId: this.campaignSlug, formData })
      
      if (success) {
        this.$notify({ type: 'success-bg', text: message })
        this.$emit('update:callRoutes', changeItemsAfterUpdate(this.callRoutes, payload))
      } else {
        !!message && this.$notify({ type: 'error-bg', duration: 15000, text: message })
      }
      
      this.loadingStatus = false
    },
    async changeDestinationPriority(route, formData) {
      this.loadingStatus = true

      const { success, payload, message } = this.isRoutingPlan
        ? await editAttachedDestinationToRoutingPlan({ routingPlanId: this.routingPlanSlug, formData })
        : await editAttachedDestination({ campaignId: this.campaignSlug, formData })

      if (success) {
        this.$notify({ type: 'success-bg', text: message })
        this.$emit('update:callRoutes', changeItemsAfterUpdate(this.callRoutes, payload))
      } else {
        !!message && this.$notify({ type: 'error-bg', duration: 15000, text: message })
      }

      this.loadingStatus = false
    },
    changeStatus(route) {
      if (route.isGroup) {
        this.changeGroupStatus(route)
      } else {
        this.changeDestinationStatus(route)
      }
    },
    async changeGroupStatus(route) {
      this.loadingStatus = true

      const formData = { status: route['status_id'] === 1 ? 2 : 1 }
      
      const { success, payload, message } = await updateGroup({ groupId: route.slug, formData })
      
      if (success) {
        this.$notify({ type: 'success-bg', text: message })
        this.$emit('update:callRoutes', changeItemsAfterUpdate(this.callRoutes, payload))
      } else {
        !!message && this.$notify({ type: 'error-bg', duration: 15000, text: message })
      }
      
      this.loadingStatus = false
    },
    async changeDestinationStatus(route) {
      this.loadingStatus = true
      
      const formData = { status: route['status_id'] === 1 ? 2 : 1 }

      const { success, payload, message } = await changeDestinationStatus({ destinationId: route.slug, formData })

      if (success) {
        this.$notify({ type: 'success-bg', text: message })
        this.$emit('update:callRoutes', changeItemsAfterUpdate(this.callRoutes, payload))
      } else {
        !!message && this.$notify({ type: 'error-bg', duration: 15000, text: message })
      }

      this.loadingStatus = false
    },
    editCallRoute(route) {
      this.$emit('openEditCallRoutePanel', route)
    },
    deleteCallRouting(route) {
      this.selectedItem = route
      this.dialogTitle = 'Delete call route'
      this.dialogSubTitle = `${route.name}`
      this.dialogMessage = 'Are you sure you want to delete this call route?'
      this.confirmDialog = true
    },
    goToDeleteCallRouting() {
      if (!!this.selectedItem.isGroup) {
        this.goToDetachGroup()
      } else {
        this.goToDetachDestination()
      }
    },
    async goToDetachGroup() {
      this.loadingAction = true
      
      const params = { group: this.selectedItem.slug }
      
      const { success, message } = this.isRoutingPlan
        ? await detachGroupFromRoutingPlan({ routingPlanId: this.routingPlanSlug, params })
        : await detachGroupFromCampaign({ campaignId: this.campaignSlug, params })
      
      if (success) {
        this.$notify({ type: 'success-bg', text: message })
        this.afterDelete(this.selectedItem.slug)
        this.confirmDialog = false
      } else {
        !!message && this.$notify({ type: 'error-bg', duration: 15000, text: message })
      }
      
      this.loadingAction = false
    },
    async goToDetachDestination() {
      this.loadingAction = true

      const params = { target: this.selectedItem.slug }

      const { success, message } = this.isRoutingPlan
        ? await detachDestinationFromRoutingPlan({ routingPlanId: this.routingPlanSlug, params })
        : await detachDestinationFromCampaign({ campaignId: this.campaignSlug, params })

      if (success) {
        this.$notify({ type: 'success-bg', text: message })
        this.afterDelete(this.selectedItem.slug)
        this.confirmDialog = false
      } else {
        !!message && this.$notify({ type: 'error-bg', duration: 15000, text: message })
      }

      this.loadingAction = false
    },
    afterDelete(deletedSlug) {
      this.$emit('update:callRoutes', this.callRoutes.filter(item => item.slug !== deletedSlug))
      this.$emit('reloadCampaignManage')
    },
    customSort(items, index, isDesc) {
      const fieldName = index[0]
      const type = this.getFieldType(fieldName)

      items.sort((a, b) => {
        let f = a[fieldName]
        let s = b[fieldName]
        
        switch (fieldName) {
          case 'revenue':
            f = +a['pivot']['revenue']
            s = +b['pivot']['revenue']
            break
          case 'priority':
            f = +a['pivot']['priority']
            s = +b['pivot']['priority']
            break
          case 'weight':
            f = +a['pivot']['weight']
            s = +b['pivot']['weight']
            break
          case 'type':
            f = +a['pivot']['convert_on_id']
            s = +b['pivot']['convert_on_id']
            break
        }

        if(typeof f !== 'undefined') {
          return customSorting(f, s, !!isDesc[0], type)
        }
      })
      return items
    },
    getFieldType(name) {
      if (name === 'revenue' || name === 'priority' || name === 'weight') {
        return 'number'
      }

      if (name === 'status_id' || name === 'type') {
        return 'status'
      }

      return 'string'
    },
  },
  watch: {
    confirmDialog: {
      handler(value) {
        if (!value) {
          this.selectedItem = null
        }
      }
    },
  },
}
</script>

<style lang="scss">
.iconArrowUp {
  margin-bottom: -1px;
}
.iconArrowDown {
  margin-top: -1px;
}

.call-routing-table:not(.withWeight) {
  & > .v-data-table__wrapper {
    & > table {
      & > .v-data-table-header {
        & > tr {
          & > th:nth-child(1) {
            border-right: 1px solid var(--v-cardBorder-base);
          }
        }
      }
      
      & > tbody {
        & > tr:not(.v-data-table__empty-wrapper) {
          & > td:nth-child(1) {
            border-right: 1px solid var(--v-cardBorder-base);
          }
        }
      }
    }
  }
}

.call-routing-table.withWeight {
  & > .v-data-table__wrapper {
    & > table {
      & > .v-data-table-header {
        & > tr {
          & > th:nth-child(2) {
            border-right: 1px solid var(--v-cardBorder-base);
          }
        }
      }

      & > tbody {
        & > tr:not(.v-data-table__empty-wrapper) {
          & > td:nth-child(2) {
            border-right: 1px solid var(--v-cardBorder-base);
          }
        }
      }
    }
  }
}
</style>
