import axios from '@/plugins/axios'
import { getAccountId } from '@/helper/auth-helper'

const getRoutingPlans = params => axios.get(`${getAccountId()}/routing-plans/paginated`, { params })
const getAllRoutingPlans = () => axios.get(`${getAccountId()}/routing-plans/all`)
const getDetailRoutingPlan = routingPlanId => axios.get(`${getAccountId()}/routing-plans/${routingPlanId}`)
const getManageForRoutingPlan = routingPlanId => axios.get(`${getAccountId()}/routing-plans/${routingPlanId}/manage`)
const createRoutingPlan = formData => axios.post(`${getAccountId()}/routing-plans`, formData)
const editRoutingPlan = ({ routingPlanId, formData }) => axios.patch(`${getAccountId()}/routing-plans/${routingPlanId}`, formData)
const deleteRoutingPlan = routingPlanId => axios.delete(`${getAccountId()}/routing-plans/${routingPlanId}`)

const attachDestinationToRoutingPlan = ({ routingPlanId, formData }) => axios.patch(`${getAccountId()}/routing-plans/${routingPlanId}/targets`, formData)
const editAttachedDestinationToRoutingPlan = ({ routingPlanId, formData }) => axios.put(`${getAccountId()}/routing-plans/${routingPlanId}/targets`, formData)
const detachDestinationFromRoutingPlan = ({ routingPlanId, params }) => axios.delete(`${getAccountId()}/routing-plans/${routingPlanId}/targets`, { params })

const attachGroupToRoutingPlan = ({ routingPlanId, formData }) => axios.patch(`${getAccountId()}/routing-plans/${routingPlanId}/target-groups`, formData)
const editAttachedGroupToRoutingPlan = ({ routingPlanId, formData }) => axios.put(`${getAccountId()}/routing-plans/${routingPlanId}/target-groups`, formData)
const detachGroupFromRoutingPlan = ({ routingPlanId, params }) => axios.delete(`${getAccountId()}/routing-plans/${routingPlanId}/target-groups`, { params })

export {
  getRoutingPlans, getAllRoutingPlans, getDetailRoutingPlan, getManageForRoutingPlan, createRoutingPlan, editRoutingPlan, deleteRoutingPlan, attachDestinationToRoutingPlan, editAttachedDestinationToRoutingPlan, detachDestinationFromRoutingPlan, attachGroupToRoutingPlan, editAttachedGroupToRoutingPlan, detachGroupFromRoutingPlan
}