<template>
  <div
    class="input-number-for-table-container"
    :style="{ 'max-width': maxWidthInput }"
  >
    <v-text-field
      v-bind="$attrs"
      v-on="$listeners"
      class="input-number-for-table font-normal"
      :class="{ 'error-line': invalidate }"
      color="tableBodyText"
      v-model.number="fieldValue"
      @focus="changeFocus(true)"
      @blur="changeFocus(false)"
      @keypress="onlyNumbers"
      @change="checkValue"
      type="number"
      :min="min"
      :step="step"
      dense
    >
      <slot v-for="slot in Object.keys($slots)" :name="slot" :slot="slot"/>
      <template v-for="(_, slot) of $scopedSlots" v-slot:[slot]="scope">
        <slot :name="slot" v-bind="scope"/>
      </template>
    </v-text-field>
  </div>
</template>

<script>
export default {
  name: 'InputNumberForTable',
  inheritAttrs: false,
  props: {
    value: {
      type: Number | String,
      required: true
    },
    min: {
      type: Number | String,
      default: 1
    },
    step: {
      type: Number | String,
      default: 1
    },
    maxWidth: {
      type: Number | String,
      default: 1000
    },
    invalidate: {
      type: Boolean,
      default: false
    },
    visibleArrowAllTheTime: {
      type: Boolean,
      default: false
    },
  },
  data: () => ({
    focusField: false,
  }),
  computed: {
    fieldValue: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', +value)
        this.$emit('update', +value)
      }
    },
    maxWidthInput() {
      const str = this.fieldValue
      const length = str.toString().length ? str.toString().length -1 : 0
      const width = 13
      //this.focusField ? 32 : 22
      const fieldWidth = width + length * 7.3
      return +this.maxWidth < +fieldWidth ? `${this.maxWidth}px` : `${fieldWidth}px`
    }
  },
  methods: {
    inc() {
      this.fieldValue = +(parseFloat(this.fieldValue) + parseFloat(this.step)).toFixed(2)
    },
    dec() {
      if (this.fieldValue > this.min) {
        this.fieldValue = +(parseFloat(this.fieldValue) - parseFloat(this.step)).toFixed(2)
      }
    },
    changeFocus(val) {
      this.focusField = val
      if (!val) {
        this.$emit('focusLost', this.fieldValue)
      }
    },
    onlyNumbers(event) {
      const regex = new RegExp('^[0-9]*[.]?[0-9]+(?:[eE][-+]?[0-9]+)?$')
      const key = `${this.fieldValue}` + String.fromCharCode(!event.charCode ? event.which : event.charCode)
      if (isNaN(key) || !regex.test(parseFloat(key))) {
        event.preventDefault()
        return false
      }
    },
    checkValue(val) {
      if (val === '' || val < this.min) {
        this.fieldValue = this.min
      }
    }
  }
}
</script>
