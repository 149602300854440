<template>
  <TooltipAction bottom message="Weight highest limit is 100" :disabled="!invalidate">
    <template #activator>
      <div class="priority-wrapper d-flex justify-center" :class="{ 'loading': loadingStatus }" v-click-outside="forceUpdate">
        <InputNumberForTable
          v-model="fieldValue"
          class="hide-line"
          :min="1"
          visibleArrowAllTheTime
          :maxWidth="40"
          :invalidate="invalidate"
          @update="updateValue"
        />

        <div class="d-flex flex-column pl-2" v-if="!hideArrow">
          <v-icon
            class="iconArrowUp"
            color="defaultIconColor"
            size="10"
            :disabled="loadingStatus"
            @click="changeValue(true, $event)"
          >$arrowUp</v-icon>

          <v-icon
            class="iconArrowDown"
            color="defaultIconColor"
            size="10"
            :disabled="loadingStatus"
            @click="changeValue(false, $event)"
          >$arrowDown</v-icon>
        </div>
      </div>
    </template>
  </TooltipAction>
</template>

<script>
import InputNumberForTable from '@/components/inputs/InputNumberForTable'
import TooltipAction from '@/components/tooltip/TooltipAction'

export default {
  name: 'TableInputNumberControl',
  inheritAttrs: false,
  components: { InputNumberForTable, TooltipAction },
  props: {
    value: {
      type: Number | String,
      required: true
    },
    item: {
      type: Object,
      default: () => ({})
    },
    loadingStatus: {
      type: Boolean,
      default: false
    },
    isPriority: {
      type: Boolean,
      default: false
    },
    hideArrow: {
      type: Boolean,
      default: false
    },
    max: {
      type: Number,
      default: 0
    },
  },
  data: () => ({
    interval: null,
  }),
  computed: {
    fieldValue: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', +value)
      }
    },
    nameField() {
      return this.isPriority ? 'priority' : 'weight'
    },
    invalidate() {
      return !!this.max ? +this.fieldValue > +this.max : false
    }
  },
  methods: {
    changeValue(priorityUp, event) {
      if (!this.item) {
        return
      }
      
      const shiftKey = event?.shiftKey ? event.shiftKey : false
      let priority = this.item.pivot[this.nameField]

      if (priorityUp) {
        priority = shiftKey ? +priority + 10 : +priority + 1
      } else {
        if (this.item.pivot[this.nameField] > 1) {
          priority = shiftKey ? +priority - 10 : priority - 1
          priority = priority < 1 ? 1 : priority
        } else {
          return
        }
      }
      this.updateValue(priority)
    },
    updateValue(value) {
      this.fieldValue = value
      this.setTimer()
    },
    setTimer() {
      clearInterval(this.interval)

      this.interval = setInterval(() => {
        this.saveChanges()
      }, 1500)
    },
    forceUpdate() {
      if (!this.interval) {
        return
      }

      this.saveChanges()
    },
    saveChanges() {
      clearInterval(this.interval)

      if (this.invalidate) {
        return
      }

      this.interval = null
      this.fieldValue = !!(+this.fieldValue) ? +this.fieldValue : 1
      this.$emit('changeValueAction', this.fieldValue)
    },
  },
  beforeDestroy() {
    this.forceUpdate()
  },
}
</script>