<template>
  <div
    id="call-routing"
    class="add-destination-container"
    :class="{ 'panel-disabled disable-block': !routingPlanSlug }"
  >
    <v-row
      class="d-flex align-center pt-15 px-12"
      :class="checkSm ? 'pt-sp-23 px-sp-20' : 'pt-sm-25 px-sm-25'"
      no-gutters
    >
      <v-col cols="12" md="3" lg="2">
        <TextDescription :description="description.destination" />
      </v-col>
      
      <v-col cols="12" md="9" lg="10">
        <v-row no-gutters>
          <v-col
            class="d-flex flex-wrap justify-space-between table-container pb-sm-6"
            :style="checkMd ? 'max-width: 1037px;' : ''"
            cols="12" :lg="checkMd ? 11 : 9" :offset-lg="checkMd ? 1 : 3"
          >
            <SearchField
              v-model="search"
              class="mr-4 mr-sm-11 mb-4"
              :height="48"
            />

            <div class="d-flex">
              <ActionOutlinedButton class="mb-4" @click="openCloseAddDestinationPanel">Add Destination</ActionOutlinedButton>

              <ActionOutlinedButton class="ml-4 ml-sm-11 mb-4" @click="openCloseAddGroupPanel">Add Group</ActionOutlinedButton>
            </div>
          </v-col>
          
          <v-col
            class="table-container"
            :style="checkMd ? 'max-width: 1037px;' : ''"
            cols="12" :lg="checkMd ? 11 : 9" :offset-lg="checkMd ? 1 : 3"
          >
            <AddedDestinations
              :routingPlanSlug="routingPlanSlug"
              :callRoutes.sync="callRoutesLocal"
              :loadingDetail="loadingDetailRoutingPlan"
              :conversionOptions="conversionOptions"
              :search="search"
              isRoutingPlan
              @reloadCampaignManage="reloadCampaignManage"
              @openEditCallRoutePanel="openEditCallRoutePanel"
            />
          </v-col>
          
          <v-col
            class="divider-container pt-sm-25"
            :class="!visiblePanel ? 'pt-16' : 'pt-12'"
            cols="9" offset="3"
          ></v-col>
        </v-row>
      </v-col>
    </v-row>
    
    <v-slide-y-transition>
      <div
        id="call-routing-panel"
        class="destination-container expandPanelBg panel-disabled"
        v-if="visiblePanel"
      >
        <CreateCallRoutePanel
          v-if="visibleCreateCallRoutePanel"
          :description="createRouteDescription"
          :routingPlanSlug="routingPlanSlug"
          :conversionOptions="conversionOptions"
          :duplicateRevenueOptions="duplicateRevenueOptions"
          :freeDestinations="freeDestinations"
          :freeGroups="freeGroups"
          :newSlug.sync="newSlugAfterCreate"
          :isGroup="isGroupCreate"
          isRoutingPlan
          @createSuccess="createCallRouteSuccess"
          @openCreateDestinationPanel="openCreateDestinationPanel"
          @openCreateGroupPanel="openCreateGroupPanel"
          @closePanel="closePanel"
        />
        
        <EditCallRoutePanel
          v-if="visibleEditCallRoutePanel"
          :description="editRouteDescription"
          :routingPlanSlug="routingPlanSlug"
          :conversionOptions="conversionOptions"
          :duplicateRevenueOptions="duplicateRevenueOptions"
          :editRoute="editRoute"
          :isGroup="isGroupEdit"
          isRoutingPlan
          @updateSuccess="updateCallRouteSuccess"
          @closePanel="closePanel"
        />
        
        <CardDestination
          v-if="visibleCreateDestinationPanel"
          class="expandPanelBg pa-12 py-md-20"
          :class="checkSm ? 'py-sp-12 px-sp-20' : 'py-sm-16 px-sm-25'"
          :description="description.createDestination"
          :capOnOptionsFromRelative="capOnOptions"
          isExpandPanel
          onPageCreateCampaign
          @closePanel="closePanel"
          @createSuccess="createDestinationSuccess"
          @openCreateCallRoute="openCreateCallRoute"
        />
        
        <CardGroup
          v-if="visibleCreateGroupPanel"
          class="expandPanelBg pa-12 py-md-20"
          :class="checkSm ? 'py-sp-12 px-sp-20' : 'py-sm-16 px-sm-25'"
          :description="description.createGroup"
          isExpandPanel
          onPageCreateCampaign
          @closePanel="closePanel"
          @createSuccess="createGroupSuccess"
          @openCreateCallRoute="openCreateCallRoute"
        />
      </div>
    </v-slide-y-transition>
  </div>
</template>

<script>
import TextDescription from '@/components/description/TextDescription'
import AddedDestinations from '@/components/tables/AddedDestinations'
import ActionOutlinedButton from '@/components/buttons/ActionOutlinedButton'
import CreateCallRoutePanel from '@/components/campaign/panels/CreateCallRoutePanel'
import EditCallRoutePanel from '@/components/campaign/panels/EditCallRoutePanel'
import CardDestination from '@/components/cards/CardDestination'
import CardGroup from '@/components/cards/CardGroup'
import SearchField from '@/components/inputs/SearchField'

import { getCapOnOptions, getConversionOptions, getDuplicateRevenueOptions } from '@/api/destination-routes'

export default {
  name: 'CallRouting',
  inheritAttrs: false,
  components: { TextDescription, AddedDestinations, ActionOutlinedButton, CreateCallRoutePanel, EditCallRoutePanel, CardDestination, CardGroup, SearchField },
  props: {
    description: {
      type: Object,
      required: true
    },
    routingPlanDestinationsAndGroups: {
      type: Array,
      required: true
    },
    routingPlanSlug: {
      type: String | Number,
      required: true
    },
    loadingDetailRoutingPlan: {
      type: Boolean,
      required: true
    },
    freeDestinations: {
      type: Array,
      required: true
    },
    freeGroups: {
      type: Array,
      required: true
    },
  },
  data: () => ({
    callRoutesLocal: [],
    capOnOptions: [],
    conversionOptions: [],
    duplicateRevenueOptions: [],
    visibleCreateCallRoutePanel: false,
    visibleEditCallRoutePanel: false,
    visibleCreateDestinationPanel: false,
    visibleCreateGroupPanel: false,
    newSlugAfterCreate: null,
    editRoute: null,
    editRouteDescription: null,
    createRouteDescription: null,
    isGroupCreate: false,
    isGroupEdit: false,
    search: null,
  }),
  computed: {
    checkSm() {
      return this.$vuetify.breakpoint.width < 600
    },
    checkMd() {
      return this.$vuetify.breakpoint.width < 1600
    },
    visiblePanel() {
      return this.visibleCreateCallRoutePanel || this.visibleEditCallRoutePanel || this.visibleCreateDestinationPanel || this.visibleCreateGroupPanel
    },
  },
  mounted() {
    this.goToLoadCapOnOptions()
    this.goToLoadConversionOptions()
    this.goToLoadDuplicateRevenueOptions()
  },
  methods: {
    async goToLoadCapOnOptions() {
      const { success, payload, message } = await getCapOnOptions()

      if (success) {
        this.capOnOptions = payload
      } else {
        !!message && this.$notify({ type: 'error-bg', duration: 15000, text: message })
      }
    },
    async goToLoadConversionOptions() {
      const { success, payload, message } = await getConversionOptions()

      if (success) {
        this.conversionOptions = payload
      } else {
        !!message && this.$notify({ type: 'error-bg', duration: 15000, text: message })
      }
    },
    async goToLoadDuplicateRevenueOptions() {
      const { success, payload, message } = await getDuplicateRevenueOptions()

      if (success) {
        this.duplicateRevenueOptions = payload
      } else {
        !!message && this.$notify({ type: 'error-bg', duration: 15000, text: message })
      }
    },
    openCloseAddDestinationPanel() {
      if (this.visibleCreateCallRoutePanel && !this.isGroupCreate) {
        this.closePanel()
      } else {
        this.isGroupCreate = false
        this.createRouteDescription = this.description.addDestination
        this.visibleCreateCallRoutePanel = true
        this.scrollTo()
      }
    },
    openEditCallRoutePanel(route) {
      this.editRoute = route
      this.visibleEditCallRoutePanel = true
      this.editRouteDescription = this.description.editCallRoute
      this.isGroupEdit = !!route.isGroup
      this.scrollTo()
    },
    updateCallRouteSuccess(updatedRoute) {
      const index = this.callRoutesLocal.findIndex(item => item.slug === updatedRoute.slug)

      if (index + 1) {
        this.callRoutesLocal[index].pivot = updatedRoute.pivot
      }

      this.callRoutesLocal = this.callRoutesLocal.slice()

      this.closePanel()
    },
    openCloseAddGroupPanel() {
      if (this.visibleCreateCallRoutePanel && this.isGroupCreate) {
        this.closePanel()
      } else {
        this.isGroupCreate = true
        this.createRouteDescription = this.description.addGroup
        this.visibleCreateGroupPanel = false
        this.visibleCreateCallRoutePanel = true
        this.scrollTo()
      }
    },
    openCreateDestinationPanel() {
      this.visibleCreateCallRoutePanel = false
      this.visibleCreateGroupPanel = false
      this.visibleCreateDestinationPanel = true
    },
    openCreateGroupPanel() {
      this.visibleCreateDestinationPanel = false
      this.visibleCreateCallRoutePanel = false
      this.visibleCreateGroupPanel = true
    },
    openCreateCallRoute() {
      this.visibleCreateDestinationPanel = false
      this.visibleCreateGroupPanel = false
      this.visibleCreateCallRoutePanel = true
    },
    createCallRouteSuccess(newCallRoutes) {
      this.reloadCampaignManage()
      this.callRoutesLocal.push(...newCallRoutes)
      this.closePanel()
    },
    createDestinationSuccess(destination) {
      this.isGroupCreate = false
      this.reloadCampaignManage()
      this.newSlugAfterCreate = destination.slug
      this.openCreateCallRoute()
      this.scrollTo()
    },
    createGroupSuccess(slug) {
      this.isGroupCreate = true
      this.reloadCampaignManage()
      this.newSlugAfterCreate = slug
      this.openCreateCallRoute()
      this.scrollTo()
    },
    reloadCampaignManage() {
      this.$emit('reloadCampaignManage')
    },
    closePanel() {
      this.isGroupCreate = false
      this.isGroupEdit = false
      this.visibleCreateDestinationPanel = false
      this.visibleCreateCallRoutePanel = false
      this.visibleEditCallRoutePanel = false
      this.visibleCreateGroupPanel = false
      this.scrollTo(false)
    },
    scrollTo(value = true) {
      if (value) {
        setTimeout(() => {
          this.$vuetify.goTo('#call-routing-panel')
        })
      } else {
        this.$vuetify.goTo('#call-routing')
      }
    },
  },
  watch: {
    routingPlanDestinationsAndGroups: {
      handler(items) {
        this.callRoutesLocal = items
      }
    },
    visibleCreateCallRoutePanel: {
      handler(visible) {
        if (visible) {
          this.visibleCreateDestinationPanel = false
          this.visibleEditCallRoutePanel = false
          this.visibleCreateGroupPanel = false
        }
      }
    },
    visibleEditCallRoutePanel: {
      handler(visible) {
        if (visible) {
          this.visibleCreateCallRoutePanel = false
          this.visibleCreateDestinationPanel = false
          this.visibleCreateGroupPanel = false
        }
      }
    },
  },
}
</script>
