<template>
  <v-row
    class="d-flex align-center pa-12 py-md-20"
    :class="checkSm ? 'py-sp-12 px-sp-20' : 'py-sm-16 px-sm-25'"
    no-gutters
  >
    <v-col cols="12" md="3" lg="2">
      <TextDescription :description="description" />
    </v-col>
    <v-col cols="12" md="9" lg="10">
      <v-form ref="editRoute" v-model="validForm">
        <v-row no-gutters>
          <v-col
            class="d-flex flex-column justify-center pr-sm-11 pb-4 pb-sm-10"
            cols="12" sm="4" md="3"
          >
            <span class="font-normal nameField--text text-sm-right">Call Route name</span>
          </v-col>
  
          <v-col class="pb-10" cols="12" sm="8" md="9">
            <FormField
              v-model="routeName"
              class="field-container"
              readonly
            />
          </v-col>
          
          <v-col
            class="d-flex flex-column justify-center pr-sm-11 pb-4 pb-sm-10"
            cols="12" sm="4" md="3"
          >
            <span class="font-normal nameField--text text-sm-right">Rate, min $1</span>
            <portal-target
              class="field-message font-normal error--text text-sm-right"
              name="rate"
            ></portal-target>
          </v-col>
          
          <v-col class="pb-10" cols="12" sm="8" md="9">
            <InputNumberControl
              v-model="formData.revenue"
              :rules="rules"
              :min="1"
              :step="0.01"
            >
              <template #message="{ key, message }">
                <portal to="rate" v-if="!!message">
                  {{ message }}
                </portal>
              </template>
            </InputNumberControl>
          </v-col>
          
          <v-col
            class="d-flex flex-column justify-center pr-sm-11 pb-4 pb-sm-10"
            cols="12" sm="4" md="3"
          >
            <span class="font-normal nameField--text text-sm-right">Payout on</span>
          </v-col>
          <v-col class="pb-10" cols="12" sm="8" md="9">
            <ToggleButton
              v-model="formData['convert_on_id']"
              :buttons="conversionOptions"
              :class="{'flex-column': checkToggleButtonWidth}"
            />
          </v-col>
          
          <v-expand-transition>
            <v-col cols="12" v-if="formData['convert_on_id'] === 2">
              <v-row no-gutters>
                <v-col
                  class="d-flex flex-column justify-center pr-sm-11 pb-4 pb-sm-10"
                  cols="12" sm="4" md="3"
                >
                  <span class="font-normal nameField--text text-sm-right">Length</span>
                  <portal-target
                    class="field-message font-normal error--text text-sm-right"
                    name="callRoutingLength"
                  ></portal-target>
                </v-col>
                
                <v-col class="pb-10 d-flex align-center" cols="12" sm="8" md="9">
                  <InputNumberControl
                    v-model="optionalData.length"
                    :rules="rules"
                  >
                    <template #message="{ key, message }">
                      <portal to="callRoutingLength" v-if="!!message">
                        {{ message }}
                      </portal>
                    </template>
                  </InputNumberControl>
                  <div class="pl-4">
                    <span class="font-normal nameField--text">seconds</span>
                  </div>
                </v-col>
              </v-row>
            </v-col>
          </v-expand-transition>
          
          <v-col
            class="d-flex flex-column justify-center pr-sm-11 pb-4 pb-sm-10"
            cols="12" sm="4" md="3"
          >
            <span class="font-normal nameField--text text-sm-right">Duplicate Revenue Options</span>
          </v-col>
          <v-col class="pb-10" cols="12" sm="8" md="9">
            <ToggleButton
              v-model="formData['duplicate_id']"
              :buttons="duplicateRevenueOptions"
            />
          </v-col>
          
          <v-expand-transition>
            <v-col cols="12" v-if="formData['duplicate_id'] === 3">
              <v-row no-gutters>
                <v-col
                  class="d-flex flex-column justify-center pr-sm-11 pb-4 pb-sm-10"
                  cols="12" sm="4" md="3"
                >
                  <span class="font-normal nameField--text text-sm-right">Days</span>
                  <portal-target
                    class="field-message font-normal error--text text-sm-right"
                    name="days"
                  ></portal-target>
                </v-col>
                
                <v-col class="pb-10" cols="12" sm="8" md="9">
                  <InputNumberControl
                    v-model="optionalDataDuplicate['time_limit']"
                    class="field-container"
                    :rules="rules"
                  >
                    <template #message="{ key, message }">
                      <portal to="days" v-if="!!message">
                        {{ message }}
                      </portal>
                    </template>
                  </InputNumberControl>
                </v-col>
              </v-row>
            </v-col>
          </v-expand-transition>
          
          <v-col
            class="d-flex flex-column justify-center pr-sm-11 pb-4 pb-sm-10"
            cols="12" sm="4" md="3"
          >
            <span class="font-normal nameField--text text-sm-right">Priority</span>
            <portal-target
              class="field-message font-normal error--text text-sm-right"
              name="callRoutingPriority"
            ></portal-target>
          </v-col>
          
          <v-col class="pb-10" cols="12" sm="8" md="9">
            <InputNumberControl
              v-model="formData.priority"
              class="field-container"
              :rules="rules"
            >
              <template #message="{ key, message }">
                <portal to="callRoutingPriority" v-if="!!message">
                  {{ message }}
                </portal>
              </template>
            </InputNumberControl>
          </v-col>

          <v-col
            class="d-flex flex-column justify-center pr-sm-11 pb-4 pb-sm-10"
            cols="12" sm="4" md="3"
          >
            <span class="font-normal nameField--text text-sm-right">Weight</span>
            <portal-target
              class="field-message font-normal error--text text-sm-right"
              name="callRoutingWeight"
            ></portal-target>
          </v-col>

          <v-col class="pb-10" cols="12" sm="8" md="9">
            <InputNumberControl
              v-model="formData.weight"
              class="field-container"
              :rules="weightRules"
            >
              <template #message="{ key, message }">
                <portal to="callRoutingWeight" v-if="!!message">
                  {{ message }}
                </portal>
              </template>
            </InputNumberControl>
          </v-col>
          
          <v-col class="pt-5 pt-md-10" cols="12" sm="8" md="9" offset-sm="4" offset-md="3">
            <CancelButton
              class="mr-5 mr-sm-10"
              @click="closePanel"
            >
              Cancel
            </CancelButton>
            
            <ActionButton
              @click="submitForm"
              :loading="loading"
            >
              Save and Continue
            </ActionButton>
          </v-col>
        </v-row>
      </v-form>
    </v-col>
  </v-row>
</template>

<script>
import TextDescription from '@/components/description/TextDescription'
import FormField from '@/components/inputs/FormField'
import InputNumberControl from '@/components/inputs/InputNumberControl'
import ToggleButton from '@/components/buttons/ToggleButton'
import CancelButton from '@/components/buttons/CancelButton'
import ActionButton from '@/components/buttons/ActionButton'

import { editAttachedDestination, editAttachedGroup } from '@/api/campaign-routes'
import { editAttachedDestinationToRoutingPlan, editAttachedGroupToRoutingPlan } from '@/api/routing-plan-routes'
  
export default {
  name: 'EditCallRoutePanel',
  inheritAttrs: false,
  components: { TextDescription, FormField, InputNumberControl, ToggleButton, CancelButton, ActionButton, },
  props: {
    description: {
      type: Object,
      required: true
    },
    campaignSlug: {
      type: String | Number,
      default: null
    },
    routingPlanSlug: {
      type: String | Number,
      default: null
    },
    conversionOptions: {
      type: Array,
      required: true
    },
    duplicateRevenueOptions: {
      type: Array,
      required: true
    },
    editRoute: {
      type: Object,
      required: true
    },
    isGroup: {
      type: Boolean,
      default: false
    },
    isRoutingPlan: {
      type: Boolean,
      default: false
    },
  },
  data: () => ({
    validForm: true,
    loading: false,
    routeName: null,
    formData: {
      target: null,
      priority: 1,
      weight: 100,
      revenue: 1,
      'convert_on_id': 1,
      'duplicate_id': 1,
    },
    optionalData: {
      length: 10,
    },
    optionalDataDuplicate: {
      'time_limit': 1,
    },
    rules: [],
    weightRules: [],
  }),
  computed: {
    checkToggleButtonWidth() {
      return this.$vuetify.breakpoint.width < 820
    },
    checkSm() {
      return this.$vuetify.breakpoint.width < 600
    },
  },
  methods: {
    parseProps() {
      this.routeName = this.editRoute.name
      Object.keys(this.formData).forEach(name => {
        this.formData[name] = this.editRoute.pivot[name]
      })
      Object.keys(this.optionalData).forEach(name => {
        this.optionalData[name] = this.editRoute.pivot[name]
          ? this.editRoute.pivot[name]
          : 10
      })
      Object.keys(this.optionalDataDuplicate).forEach(name => {
        this.optionalDataDuplicate[name] = this.editRoute.pivot[name]
          ? this.editRoute.pivot[name]
          : 1
      })
    },
    async submitForm() {
      await this.validationForm()
      if (this.$refs.editRoute.validate()) {
        const editItemSlug = this.isGroup
          ? { group: this.editRoute.slug }
          : { target: this.editRoute.slug }
          
        let formData = Object.assign({}, this.formData, editItemSlug)

        if (this.formData['convert_on_id'] === 2) {
          formData = Object.assign(formData, this.optionalData)
        }
        if (this.formData['duplicate_id'] === 3) {
          formData = Object.assign(formData, this.optionalDataDuplicate)
        }
        
        if (this.isGroup) {
          this.goToEditAttachedGroup(formData)
        } else {
          this.goToEditAttachedDestination(formData)
        }
      }
    },
    async goToEditAttachedGroup(formData) {
      this.loading = true

      const { success, payload, message } = this.isRoutingPlan
        ? await editAttachedGroupToRoutingPlan({ routingPlanId: this.routingPlanSlug, formData })
        : await editAttachedGroup({ campaignId: this.campaignSlug, formData })

      if (success) {
        this.$notify({ type: 'success-bg', text: message })
        this.$emit('updateSuccess', payload)
      } else {
        !!message && this.$notify({ type: 'error-bg', duration: 15000, text: message })
      }

      this.loading = false
    },
    async goToEditAttachedDestination(formData) {
      this.loading = true

      const { success, payload, message } = this.isRoutingPlan
        ? await editAttachedDestinationToRoutingPlan({ routingPlanId: this.routingPlanSlug, formData })
        : await editAttachedDestination({ campaignId: this.campaignSlug, formData })

      if (success) {
        this.$notify({ type: 'success-bg', text: message })
        this.$emit('updateSuccess', payload)
      } else {
        !!message && this.$notify({ type: 'error-bg', duration: 15000, text: message })
      }

      this.loading = false
    },
    validationForm() {
      this.rules = [
        v => !!v || v === 0 ||  'This field is required'
      ]
      this.weightRules = [
        v => !!v || v === 0 ||  'This field is required',
        v => +v <= 100 || 'Weight highest limit is 100'
      ]
    },
    closePanel() {
      this.$emit('closePanel')
    },
  },
  watch: {
    editRoute: {
      immediate: true,
      handler() {
        this.parseProps()
      }
    }
  }
}
</script>
