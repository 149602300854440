<template>
  <v-row
    v-bind="$attrs"
    class="d-flex pt-15 px-12"
    :class="checkSm ? 'pt-sp-23 px-sp-20' : 'pt-sm-25 px-sm-25'"
    no-gutters
  >
    <v-col cols="12" md="3" lg="2">
      <TextDescription :description="description" />
    </v-col>
    
    <v-col cols="12" md="9" lg="10">
      <v-form ref="form" v-model="validateForm" @submit.prevent="submitForm">
        <v-row no-gutters>
          <v-col
            class="d-flex flex-column justify-center pr-sm-11 pb-4 pb-sm-10"
            cols="12" sm="4" md="3"
          >
            <span class="font-normal nameField--text text-sm-right">Routing plan name</span>
            <portal-target
              class="field-message font-normal error--text text-sm-right"
              name="routingPlanName"
            ></portal-target>
          </v-col>
          <v-col class="pb-10" cols="12" sm="8" md="9">
            <FormField
              v-model="formDataRoutingPlan.name"
              class="field-container"
              :rules="rules"
              :loading="loadingDetailRoutingPlan"
            >
              <template #message="{ key, message }">
                <portal to="routingPlanName" v-if="!!message">
                  {{ message }}
                </portal>
              </template>
            </FormField>
          </v-col>
          
          <v-col
            class="pt-2 pt-sm-10"
            cols="12" sm="8" md="9" offset-sm="4" offset-md="3"
          >
            <ActionButton type="submit" :loading="loadingAction">
              {{ routingPlanSlug ? 'Update' : 'Create' }}
            </ActionButton>
          </v-col>
          <v-col
            class="divider-container pt-16 pt-sm-25"
            cols="12" md="9" offset-md="3"
          >
            <v-divider v-if="!!routingPlanSlug" />
          </v-col>
        </v-row>
      </v-form>
    </v-col>
  </v-row>
</template>

<script>
import TextDescription from '@/components/description/TextDescription'
import FormField from '@/components/inputs/FormField'
import ActionButton from '@/components/buttons/ActionButton'

import { createRoutingPlan, editRoutingPlan } from '@/api/routing-plan-routes'

export default {
  name: 'RoutingPlanSetup',
  inheritAttrs: false,
  components: { TextDescription, FormField, ActionButton },
  props: {
    description: {
      type: Object,
      required: true
    },
    routingPlanName: {
      type: String,
      required: true
    },
    routingPlanSlug: {
      type: String | Number,
      required: true
    },
    loadingDetailRoutingPlan: {
      type: Boolean,
      default: false
    },
  },
  data: () => ({
    loadingAction: false,
    validateForm: true,
    formDataRoutingPlan: {
      name: null,
    },
    rules: [],
  }),
  computed: {
    checkSm() {
      return this.$vuetify.breakpoint.width < 600
    },
  },
  methods: {
    parseProps() {
      this.formDataRoutingPlan.name = this.routingPlanName
    },
    async submitForm() {
      await this.validationForm()
      if (this.$refs.form.validate()) {

        if (!!this.routingPlanSlug) {
          this.goToUpdateRoutingPlan()
        } else {
          this.goToCreateRoutingPlan()
        }
      }
    },
    async goToUpdateRoutingPlan() {
      this.loadingAction = true

      const { success, message } = await editRoutingPlan({ routingPlanId: this.routingPlanSlug, formData: this.formDataRoutingPlan })

      if (success) {
        this.$notify({ type: 'success-bg', text: message })
        this.rules = []
      } else {
        !!message && this.$notify({ type: 'error-bg', duration: 15000, text: message })
      }

      this.loadingAction = false
    },
    async goToCreateRoutingPlan() {
      this.loadingAction = true

      const { success, payload, message } = await createRoutingPlan(this.formDataRoutingPlan)

      if (success) {
        this.$notify({ type: 'success-bg', text: message })

        this.goToDetailRoutingPlan(payload.slug)
      } else {
        !!message && this.$notify({ type: 'error-bg', duration: 15000, text: message })
      }

      this.loadingAction = false
    },
    goToDetailRoutingPlan(routingPlanSlug) {
      this.$router.push({ query: { 'afterCreate': 'true' }, name: 'DetailRoutingPlan', params: { slug: routingPlanSlug }})
    },
    validationForm() {
      this.rules = [
        v => !!v || 'This field is required'
      ]
    },
  },
  watch: {
    routingPlanName: {
      handler(name) {
        if (!!name) {
          this.parseProps()
        }
      }
    },
  }
}
</script>
