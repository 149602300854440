<template>
  <v-row
    class="d-flex align-center pa-12 py-md-20"
    :class="checkSm ? 'py-sp-12 px-sp-20' : 'py-sm-16 px-sm-25'"
    no-gutters
  >
    <v-col cols="12" md="3" lg="2">
      <TextDescription :description="description" />
    </v-col>
    <v-col cols="12" md="9" lg="10">
      <v-row no-gutters>
        <v-col
          class="d-flex flex-column justify-center pr-sm-11 pb-4 pb-sm-10"
          cols="12" sm="4" md="3"
        >
          <span class="font-normal nameField--text text-sm-right">Choose an Option</span>
        </v-col>
        
        <v-col class="pb-10" cols="12" sm="8" md="9">
          <ToggleButton
            v-model="currentPanelOptions"
            :buttons="panelOptions"
            @input="openCreatePanel"
          />
        </v-col>
      </v-row>
      <v-form ref="create" v-model="validForm">
        <v-row no-gutters>
          <v-col
            class="d-flex flex-column justify-center pr-sm-11 pb-4 pb-sm-10"
            cols="12" sm="4" md="3"
          >
            <span class="font-normal nameField--text text-sm-right">Select {{ isGroup ? 'Groups' : 'Destinations' }}</span>
            <portal-target
              class="field-message font-normal error--text text-sm-right"
              name="selectDestinations"
            ></portal-target>
          </v-col>
          
          <v-col class="pb-10" cols="12" sm="8" md="9">
            <AutocompleteField
              class="field-container"
              v-model="changedItems"
              :items="targets"
              item-text="name"
              item-value="slug"
              multiple
              :rules="rulesArray"
              checkBg
            >
              <template #message="{ key, message }">
                <portal to="selectDestinations" v-if="!!message">
                  {{ message }}
                </portal>
              </template>
              
              <template #selection="{ item, index }">
                <span class="nameField--text" v-if="index === 0">{{ item.name }}</span>
                <span
                  v-if="index === 1"
                  class="grey--text caption pl-6"
                >+{{ changedItems.length - 1 }} others</span>
              </template>
            </AutocompleteField>
          </v-col>
          
          <v-col
            class="d-flex flex-column justify-center pr-sm-11 pb-4 pb-sm-10"
            cols="12" sm="4" md="3"
          >
            <span class="font-normal nameField--text text-sm-right">Rate, min $1</span>
            <portal-target
              class="field-message font-normal error--text text-sm-right"
              name="rate"
            ></portal-target>
          </v-col>
          
          <v-col class="pb-10" cols="12" sm="8" md="9">
            <InputNumberControl
              v-model="formData.revenue"
              :rules="rules"
              :min="1"
              :step="0.01"
            >
              <template #message="{ key, message }">
                <portal to="rate" v-if="!!message">
                  {{ message }}
                </portal>
              </template>
            </InputNumberControl>
          </v-col>
          
          <v-col
            class="d-flex flex-column justify-center pr-sm-11 pb-4 pb-sm-10"
            cols="12" sm="4" md="3"
          >
            <span class="font-normal nameField--text text-sm-right">Payout on</span>
          </v-col>
          <v-col class="pb-10" cols="12" sm="8" md="9">
            <ToggleButton
              v-model="formData['convert_on_id']"
              :buttons="conversionOptions"
              :class="{'flex-column': checkToggleButtonWidth}"
            />
          </v-col>
          
          <v-expand-transition>
            <v-col cols="12" v-if="formData['convert_on_id'] === 2">
              <v-row no-gutters>
                <v-col
                  class="d-flex flex-column justify-center pr-sm-11 pb-4 pb-sm-10"
                  cols="12" sm="4" md="3"
                >
                  <span class="font-normal nameField--text text-sm-right">Length</span>
                  <portal-target
                    class="field-message font-normal error--text text-sm-right"
                    name="callRoutingLength"
                  ></portal-target>
                </v-col>
                
                <v-col class="pb-10 d-flex align-center" cols="12" sm="8" md="9">
                  <InputNumberControl
                    v-model="optionalData.length"
                    :rules="rules"
                  >
                    <template #message="{ key, message }">
                      <portal to="callRoutingLength" v-if="!!message">
                        {{ message }}
                      </portal>
                    </template>
                  </InputNumberControl>
                  <div class="pl-4">
                    <span class="font-normal nameField--text">seconds</span>
                  </div>
                </v-col>
              </v-row>
            </v-col>
          </v-expand-transition>
          
          <v-col
            class="d-flex flex-column justify-center pr-sm-11 pb-4 pb-sm-10"
            cols="12" sm="4" md="3"
          >
            <span class="font-normal nameField--text text-sm-right">Duplicate Revenue Options</span>
          </v-col>
          <v-col class="pb-10" cols="12" sm="8" md="9">
            <ToggleButton
              v-model="formData['duplicate_id']"
              :buttons="duplicateRevenueOptions"
            />
          </v-col>
          
          <v-expand-transition>
            <v-col cols="12" v-if="formData['duplicate_id'] === 3">
              <v-row no-gutters>
                <v-col
                  class="d-flex flex-column justify-center pr-sm-11 pb-4 pb-sm-10"
                  cols="12" sm="4" md="3"
                >
                  <span class="font-normal nameField--text text-sm-right">Days</span>
                  <portal-target
                    class="field-message font-normal error--text text-sm-right"
                    name="days"
                  ></portal-target>
                </v-col>
                
                <v-col class="pb-10" cols="12" sm="8" md="9">
                  <InputNumberControl
                    v-model="optionalDataDuplicate['time_limit']"
                    class="field-container"
                    :rules="rules"
                  >
                    <template #message="{ key, message }">
                      <portal to="days" v-if="!!message">
                        {{ message }}
                      </portal>
                    </template>
                  </InputNumberControl>
                </v-col>
              </v-row>
            </v-col>
          </v-expand-transition>
          
          <v-col
            class="d-flex flex-column justify-center pr-sm-11 pb-4 pb-sm-10"
            cols="12" sm="4" md="3"
          >
            <span class="font-normal nameField--text text-sm-right">Priority</span>
            <portal-target
              class="field-message font-normal error--text text-sm-right"
              name="callRoutingPriority"
            ></portal-target>
          </v-col>
          
          <v-col class="pb-10" cols="12" sm="8" md="9">
            <InputNumberControl
              v-model="formData.priority"
              class="field-container"
              :rules="rules"
            >
              <template #message="{ key, message }">
                <portal to="callRoutingPriority" v-if="!!message">
                  {{ message }}
                </portal>
              </template>
            </InputNumberControl>
          </v-col>

          <v-col
            class="d-flex flex-column justify-center pr-sm-11 pb-4 pb-sm-10"
            cols="12" sm="4" md="3"
          >
            <span class="font-normal nameField--text text-sm-right">Weight</span>
            <portal-target
              class="field-message font-normal error--text text-sm-right"
              name="callRoutingWeight"
            ></portal-target>
          </v-col>

          <v-col class="pb-10" cols="12" sm="8" md="9">
            <InputNumberControl
              v-model="formData.weight"
              class="field-container"
              :rules="weightRules"
            >
              <template #message="{ key, message }">
                <portal to="callRoutingWeight" v-if="!!message">
                  {{ message }}
                </portal>
              </template>
            </InputNumberControl>
          </v-col>
          
          <v-col class="pt-5 pt-md-10" cols="12" sm="8" md="9" offset-sm="4" offset-md="3">
            <CancelButton
              class="mr-5 mr-sm-10"
              @click="closePanel"
            >
              Cancel
            </CancelButton>
            
            <ActionButton
              @click="submitForm"
              :loading="loading"
            >
              Save and Continue
            </ActionButton>
          </v-col>
        </v-row>
      </v-form>
    </v-col>
  </v-row>
</template>

<script>
import TextDescription from '@/components/description/TextDescription'
import ToggleButton from '@/components/buttons/ToggleButton'
import CancelButton from '@/components/buttons/CancelButton'
import ActionButton from '@/components/buttons/ActionButton'
import AutocompleteField from '@/components/select/AutocompleteField'
import FormField from '@/components/inputs/FormField'
import InputNumberControl from '@/components/inputs/InputNumberControl'

import { attachDestinationToCampaign, attachGroupToCampaign } from '@/api/campaign-routes'
import { attachDestinationToRoutingPlan, attachGroupToRoutingPlan } from '@/api/routing-plan-routes'

export default {
  name: 'CreateCallRoutePanel',
  inheritAttrs: false,
  components: { TextDescription, ToggleButton, CancelButton, ActionButton, AutocompleteField, FormField, InputNumberControl },
  props: {
    description: {
      type: Object,
      required: true
    },
    campaignSlug: {
      type: String | Number,
      default: null
    },
    routingPlanSlug: {
      type: String | Number,
      default: null
    },
    conversionOptions: {
      type: Array,
      required: true
    },
    duplicateRevenueOptions: {
      type: Array,
      required: true
    },
    freeDestinations: {
      type: Array,
      required: true
    },
    freeGroups: {
      type: Array,
      required: true
    },
    newSlug: {
      type: String | Number,
      default: null
    },
    isGroup: {
      type: Boolean,
      default: false
    },
    isRoutingPlan: {
      type: Boolean,
      default: false
    },
  },
  data: () => ({
    validForm: true,
    loading: false,
    currentPanelOptions: 1,
    panelOptions: [
      { name: 'Add Existing', id: 1 },
      { name: 'Create new', id: 2 },
    ],
    targets: [],
    changedItems: [],
    formData: {
      priority: 1,
      weight: 100,
      revenue: 1,
      'convert_on_id': 1,
      'duplicate_id': 1,
    },
    optionalData: {
      length: 10,
    },
    optionalDataDuplicate: {
      'time_limit': 1,
    },
    rules: [],
    weightRules: [],
    rulesArray: [],
  }),
  computed: {
    checkToggleButtonWidth() {
      return this.$vuetify.breakpoint.width < 820
    },
    checkSm() {
      return this.$vuetify.breakpoint.width < 600
    },
  },
  methods: {
    async submitForm() {
      await this.validationForm()
      if (this.$refs.create.validate()) {
        let formData = Object.assign({}, this.formData)
        
        if (this.isGroup) {
          formData = Object.assign(formData, {
            group: this.changedItems
          })
        } else {
          formData = Object.assign(formData, {
            target: this.changedItems
          })
        }

        if (this.formData['convert_on_id'] === 2) {
          formData = Object.assign(formData, this.optionalData)
        }
        if (this.formData['duplicate_id'] === 3) {
          formData = Object.assign(formData, this.optionalDataDuplicate)
        }
        
        if (this.isGroup) {
          this.goToAttachGroupToCampaign(formData)
        } else {
          this.goToAttachDestinationToCampaign(formData)
        }
      } 
    },
    async goToAttachGroupToCampaign(formData) {
      this.loading = true

      const { success, payload, message } = this.isRoutingPlan
        ? await attachGroupToRoutingPlan({ routingPlanId: this.routingPlanSlug, formData })
        : await attachGroupToCampaign({ campaignId: this.campaignSlug, formData })

      if (success) {
        this.$notify({ type: 'success-bg', text: message })
        this.$emit('createSuccess', payload.map(item => ({
          ...item,
          isGroup: true,
        })))
      } else {
        !!message && this.$notify({ type: 'error-bg', duration: 15000, text: message })
      }

      this.loading = false
    },
    async goToAttachDestinationToCampaign(formData) {
      this.loading = true

      const { success, payload, message } = this.isRoutingPlan
        ? await attachDestinationToRoutingPlan({ routingPlanId: this.routingPlanSlug, formData })
        : await attachDestinationToCampaign({ campaignId: this.campaignSlug, formData })

      if (success) {
        this.$notify({ type: 'success-bg', text: message })
        this.$emit('createSuccess', payload)
      } else {
        !!message && this.$notify({ type: 'error-bg', duration: 15000, text: message })
      }

      this.loading = false
    },
    validationForm() {
      this.rules = [
        v => !!v || v === 0 ||  'This field is required'
      ]
      this.weightRules = [
        v => !!v || v === 0 ||  'This field is required',
        v => +v <= 100 || 'Weight highest limit is 100'
      ]
      this.rulesArray = [
        v => !!v && !!v.length || 'This field is required'
      ]
    },
    closePanel() {
      this.$emit('closePanel')
    },
    openCreatePanel() {
      if (this.isGroup) {
        this.$emit('openCreateGroupPanel')
      } else {
        this.$emit('openCreateDestinationPanel') 
      }
    },
  },
  watch: {
    isGroup: {
      immediate: true,
      handler(value) {
        this.targets = value ? this.freeGroups : this.freeDestinations
      }
    },
    freeDestinations: {
      handler() {
        if (this.isGroup) {
          return
        }
        
        this.targets = this.freeDestinations
        if (this.newSlug) {
          this.changedItems.push(this.newSlug)
          this.$emit('update:newSlug', null)
        }
      }
    },
    freeGroups: {
      handler() {
        if (!this.isGroup) {
          return
        }

        this.targets = this.freeGroups
        if (this.newSlug) {
          this.changedItems.push(this.newSlug)
          this.$emit('update:newSlug', null)
        }
      }
    },
  },
}
</script>
